$primary: #274584;
$bleu: #274584;

@import "./app";

@import '../../public/js/OwlCarousel2-2.3.4/dist/assets/owl.carousel.min';
@import '../../public/js/OwlCarousel2-2.3.4/dist/assets/owl.theme.default.min';

.carousel-item {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  max-height: 600px;

  h1{
    line-height: 1.3;
  }
}
.icon-wrapper{
  width: 95px;
  text-align: center;
  height: 95px;
  border-radius: 50%;
  border: 2px solid #e2e2e2;
  background-color: $bleu;
  text-align: center;

  .zmdi{
    color: #fff;
    font-size: 48px;
    line-height: 91px;
    position: relative;
    z-index: 1;
  }
}
.icon-wrapper:hover{
  background-color: #fff;
  .zmdi{
    color: $bleu !important;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 0s;
    left: -50px;
  }
}

.icons-container {
  .icon-container {
    float: left;
    width: 112px;
    height: 100%;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 200;
    line-height: 30px;
  }
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

h2{
  @extend .fw-light;
}

.fw-200{
  font-weight: 100;
}

.fw-800{
  font-weight: 800;
}

.bg-blue-light{
  background-color: #e3f6f9;
}
.lh-13{
  line-height: 1.3;
}
.lh-15{
  line-height: 1.5;
}

/*--------------------------------------------------------------
1.0 - Transition
--------------------------------------------------------------*/
.dt-sc-button.with-icon.icon-right.type1, .dt-sc-button.with-icon.icon-right.type1:after, .dt-sc-colored-big-buttons:hover span, .dt-sc-partially-rounded, .dt-sc-button.with-icon.icon-left span:after, .dt-sc-button.no-border-bg:before, .dt-sc-button:before, .dt-sc-button:after {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.dt-sc-button {
  font-size: 12px;
  text-transform: inherit;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 8px 15px;
  line-height: normal;
  display: inline-block;
  margin: 10px 0px 0px;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1;
}

.dt-sc-button {
  background: none;
  color: $bleu;
  background-size: 50%;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.dt-sc-button:hover {
  color: #ffffff;
  background-color: $bleu;
}

.dt-sc-button:before {
  position: absolute;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-width: 3px;
  border-style: solid;
  left: -10px;
  bottom: -10px;
  border-top: none !important;
  border-right: none !important;
  border-color: $bleu !important;
}

.dt-sc-button:after {
  position: absolute;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-width: 3px;
  border-style: solid;
  right: -10px;
  top: -10px;
  border-bottom: none;
  border-left: none;
  border-color: $bleu !important;
}

.dt-sc-button:hover:before {
  width: 100%;
  height: 0;
  left: 0;
}

.dt-sc-button:hover:after {
  width: 100%;
  height: 0;
  right: 0;
}

.dt-sc-button.rounded-border, .dt-sc-button.bordered {
  border: 3px solid;
  border-radius: 3px;
  background: none;
}

.dt-sc-button.transparent {
  background: none;
  border: none;
  padding: 0;
}

.dt-sc-button.bordered, .dt-sc-button.bordered:hover {
  border-radius: 0px;

}
.dt-sc-button.bordered:hover {
  background-color: $bleu;
  color: #ffffff;
  border-radius: 5px;
}


.dt-sc-button.medium {
  font-size: 16px;
  padding: 12px 30px 10px;
}

/**
portfolio image de la home
 */

.filter-button
{
  font-size: 13px;
  font-weight: 600;
  padding: 12px 20px 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid;
  margin: 0px 10px 20px;
  display: inline-block;
  position: relative;
  border-radius: 0;

}
.filter-button:hover
{
  font-size: 15px;
  border: 1px solid $bleu;
  text-align: center;
  color: #ffffff;
  background-color: $bleu;

}
.btn-default:active .filter-button:active, .filter-button.active
{
  background-color: $bleu;
  color: white;
}

.gallery_product:hover{
  .description{
    display: block;
  }
  .figure {
    opacity: 1;
    animation: none;
    -moz-animation: none;
    -ms-animation: none;
    -webkit-animation: none;
  }
}
.gallery_product
{
  margin-bottom: 30px;
  .description {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: red;
    text-align: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);

    p {
      color: #fff;
      margin-top: 35%;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .figure {
    animation: pulselow 1s infinite;
    -moz-animation: pulselow 1s infinite;
    -webkit-animation: pulselow 1s infinite;
    -ms-animation: pulselow 1s infinite;
  }




  .image-overlay {
    background: rgba(0, 0, 0, 0.6);
    left: 10px;
    top: 10px;
    width: auto;
    height: auto;
    right: 10px;
    bottom: 10px;
  }

  .image-overlay {
    opacity: 1;
    position: absolute;
    opacity: 0;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  .image-overlay .links {
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;

    a{
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: none;
      background: none;
      width: auto;
      margin: 0px;
      padding: 0px 10px;
    }
  }
}




@keyframes pulselow {
  0% { opacity:0.1; } 50% { opacity:0.3; } 100% { opacity:0.1; }
}

@-moz-keyframes pulselow {
  0% { opacity:0.1; } 50% { opacity:0.3; } 100% { opacity:0.1; }
}

@-webkit-keyframes pulselow {
  0% { opacity:0.1; } 50% { opacity:0.3; } 100% { opacity:0.1; }
}

@-ms-keyframes pulselow {
  0% { opacity:0.1; } 50% { opacity:0.3; } 100% { opacity:0.1; }
}

@keyframes pulsemedium {
  0% { opacity:0.3; } 50% { opacity:0.1; } 100% { opacity:0.3; }
}

@-moz-keyframes pulsemedium {
  0% { opacity:0.3; } 50% { opacity:0.1; } 100% { opacity:0.3; }
}

@-webkit-keyframes pulsemedium {
  0% { opacity:0.3; } 50% { opacity:0.1; } 100% { opacity:0.3; }
}

@-ms-keyframes pulsemedium {
  0% { opacity:0.3; } 50% { opacity:0.1; } 100% { opacity:0.3; }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}




#quelques-chiffres{
  background-attachment: scroll;
  background-color: #e3f6f9;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;

  .dt-sc-counter.type2:hover .icon-wrapper2 span.zmdi:after{
    border-color: $bleu;
  }
  .dt-sc-counter.type2:hover .icon-wrapper2 span.zmdi:after{
    opacity: 1;
    transform: scale(2.5);
  }
  .dt-sc-counter.type2:hover .icon-wrapper2 span.zmdi{
    background-color: $bleu;
    color: #fff;
    font-size: 40px;
    width: 96px;
    height: 99px;
    border-radius: 50%;
    animation: zoom-in-zoom-out 1s ease 1;
    line-height: 98px;

  }
  .dt-sc-counter.type2:hover .icon-wrapper2 span.zmdi:after{

    opacity: 1;
    transform: scale(2.5);
  }

  .dt-sc-counter.type2 .icon-wrapper2 span.zmdi:after{
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    content: "";
    display: inline-block;
    float: none;
    height: 50px;
    left: 0;
    margin: 25px auto;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 50px;
  }

  .dt-sc-counter.type2 .icon-wrapper2 span.zmdi:after{
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    content: "";
    display: inline-block;
    float: none;
    height: 50px;
    left: 0;
    margin: 25px auto;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 50px;
  }
  .dt-sc-counter {
    font-size: 30px;
    color: $bleu;
    font-weight: 800;

    .dt-sc-counter-number {
      font: 800 40px system-ui;
    }

    .icon-wrapper2{
      @extend .m-auto;
      margin-bottom: 20px;
      .zmdi{
        color: $bleu;
        font-size: 48px;
        line-height: 91px;
        position: relative;
        z-index: 1;
      }
    }
    .icon-wrapper2:hover{
      transition: all 0.3s linear;
    }
  }

  h4{
    font-size: 19px;
    font-weight: 300;
    margin: 30px 0px 0px;
    position: relative;
  }

  h4:before {
    background: #4D4D4D;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -15px;
    width: 30px;
  }

  h4:after {
    background: #4D4D4D;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -15px;
    width: 0;
  }

}


.testimonial-quote{
  display: inline-block;
  clear: both;
  margin: 0px 0px 40px;
  padding: 0px 0 30px;
  width: 100%;
  position: relative;
  background: #f2f5f6;

  blockquote {
    padding: 40px 70px 20px 100px;
    margin: 0px;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
  }
  blockquote:after {
    content: '\f1b2';
    font-family: 'Material-Design-Iconic-Font';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 44px;
    left: 40px;
    right: auto;
    margin: auto;
    color: $bleu;
  }
}

.testimonial-quote:before {
  content: "";
  position: absolute;
  left: 22px;
  bottom: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f2f5f6;
  background: none;
}
.testimonial-author {
  width: 100%;
  float: left;
  position: relative;

  > span {
    width: 80px;
    float: left;
    border-color: rgba(0, 0, 0, 0.1);
  }

  img {
    max-width: 80px;
    float: left;
    border-radius: 50%;
    max-width: 100%;
    height: auto;
    display: inherit;
  }

  cite {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: auto;
    bottom: auto;

    small{
      color: rgba(0, 0, 0, 0.4);
      padding-left: 5px;
      margin-left: 10px;
    }
  }
}

.team{
  background-color: #fff;
  padding: 30px 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.075);
  width: 100%;
  float: left;
  margin:auto;

  .thumb{
    background: rgba(255, 255, 255, 0.1);
    border: 5px solid #e2e2e2;
    margin: 0 auto 20px;
    max-width: 150px;
    position: relative;
    transition: transform ease-out 0.1s, background 0.2s;
    border-radius: 50%;
  }
  img{
    border-radius: 50%;
    transition: all 0.3s linear;
    max-width: 100%;
    height: auto;
    display: inherit;
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
  }

  .details{
    background-color: rgba(0, 0, 0, 0);
    padding: 0 30px;
    float: left;
    width: 100%;
    padding: 30px 25px;
    text-align: center;
    position: relative;

    h4 {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }

    h5 {
      font-size: 15px;
      color: #4e4f51 !important;
      font-weight: 200 !important;
    }
  }
}
.team:hover {
  color: #fff;
  background-color: $bleu;


  h5{
    color: $bleu !important;
  }
  .thumb{
    // border-color: $bleu;
  }

  .thumb img{
    animation: zoom-in-zoom-out 1s ease 1;
  }
}

#modalContact{
  .form-group{
    margin-bottom: 8px;
  }
  .d-inline-flex.align-items-center.pt-3 {
    padding-top: 20px !important;
  }

  label{
    font-size: 13px !important;
    color: #6c6b6b !important;
    margin-bottom: 0;
  }
  input, textarea{
    height: 40px !important;
    border: 1px solid #e7e4e4 !important;
    background: #e7e4e4 !important;
    border-radius: 3px !important;
    padding: 2px !important;
    font-size: 12px !important;
    color: #6c6b6b !important;
  }
  p{
    font-size: 20px; font-weight: 400; color: $bleu;
  }
  .subtitle{
    font-size: 12px !important;
    color: grey !important;
  }
  button.submit{
    background-repeat: no-repeat;
    background-position-x: center;
    color: #f9f9f9 !important;
    height: 40px !important;
    width: 60px !important;
    margin-right: auto;
    outline: inherit;
    border: none;
    padding: 0;
  }
  .form-check label{
    @extend .small;
  }
}
