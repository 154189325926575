$primary: #000a66;
$bleu: #000a66;
$bleu-light: #2b4b98;

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/css/font-awesome";
@import "../../public/js/material-design-iconic-font/css/material-design-iconic-font";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&display=swap');
@import url('https://unpkg.com/aos@2.3.1/dist/aos.css');

.text-dark {
    color: #000 !important;
}

.fw-light{
  font-weight: 300;
}

.fw-bold, .font-bold{
  font-weight: 700;
}

.bg-blue{
  background-color: $bleu-light;
}

.bg-blue-dark{
  background-color: $bleu;
}

.text-blue, .text-bleu{
  color: $bleu;
}

.btn-shadow{
  box-shadow: 0 26px 32px 0 rgba(0,10,102,0.51) !important;
}

body{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-align: initial;
    text-transform: none;
    color: rgba(0,0,0,0.8);

}


#menuTop{
  .nav-item{
    margin-top: 0 !important;
    position: relative;
    padding-right: 20px !important;
    padding-left: 20px !important;

  }
  .nav-link{
    color: rgba(0,0,0,0.8);
    padding: 26px 0 !important;
  }
  .nav-link::before{
    border-color: $bleu;
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -12px;
    height: 1px;
    border-bottom: 3px dotted;
    bottom: 0;
    width:0px;
    overflow:hidden;
  }
    .nav-link:hover::before{
      display: block;
      transition‑property:all; transition‑duration:1s;
      width:24px;
      color: $bleu;
  }

  .nav-link:hover{
    color: $bleu;
  }
  @keyframes border-dance{
      0%
      {
        background-position: left right left right;
      }
      100%
      {
        background-position: left right left right;
      }
  }
}
#header{
  .top{
    a{
      font-size: 16px;
      @extend .pt-1, .pb-1;
    }

    .col-mail{
      .fa{
        font-size: 24px;
      }

      a:first-of-type{
        margin-left: 10px !important;
      }
      a{
        margin-left: 39px;
      }
    }
    .col-tel{
      .fa{
        font-size: 26px;
      }
      a:first-of-type{
        margin-left: 10px !important;
      }
    }
  }


  .socials{
    @extend .list-group, .list-group-horizontal, .list-unstyled;
    direction:rtl;
    padding-right: 0;

    @include media-breakpoint-up(sm) {
      padding-top: 10px  !important;
      padding-left: 10px !important;
    }
  }

  .socials li{
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
  }

  .socials a{
    height: 32px;
    width: 32px;
    padding: 0;
    color: #fff !important;
  }
  .social-facebook{
    background-color: #3b5998!important;
  }

  .social-instagram{
    background-color: #ea2c59!important;
  }

  .social-twitter{
    background-color: #00aced!important;
  }

  .social-linkedin{
    background-color: #007bb6!important;
  }

  .social-youtube{
    background-color: #a82400!important;
  }

}

@media (min-width: 1400px){
  .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1440px;
  }
}
#blocContact{
  background: url('../../public/images/bg/cabinet-medical-empuriabrava-1-scaled.jpg');
  background-position: 74% 16% !important;
  background-size: cover;
  height: 340px;
  width: 100%;
  background-repeat: no-repeat;

  div{
      padding-top: 120px;
  }
}

#blocMedecineGenerale{
  background: url('../../public/images/services/medecine-generale.jpg');
  background-size: cover;
  height: 340px;
  width: 100%;

  background-attachment: fixed;
  background-position: center;
  background-size: cover;


  div{
      padding-top: 120px;
  }
}


#blocImplants{
  background: url('../../public/images/services/traitement_alopecie.jpg');
  background-size: cover;
  height: 340px;
  width: 100%;

  background-attachment: fixed;
  background-position: center;
  background-size: cover;


  div{
      padding-top: 120px;
  }
}




#blocMedecineEsthetique{
  background: url('../../public/images/services/medicine-esthetique.png');
  background-position: 74% 16% !important;
  background-size: cover;
  height: 340px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  div{
      padding-top: 120px;
  }
}


#blocImplants{
  background: url('../../public/images/services/traitement_alopecie.jpg');
  background-size: cover;
  height: 340px;
  width: 100%;

  background-attachment: fixed;
  background-position: center;
  background-size: cover;


  div{
      padding-top: 120px;
  }
}




#blocEmpuria{
  background: url('../../public/images/services/empuriabrava.png');
  background-position: 50% 50% !important;
  background-size: cover;
  height: 340px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  div{
      padding-top: 120px;
  }
}

#blocNutrition{
  background: url('../../public/images/services/esthetique-nutrition-scaled-e1606429047731.jpg');
  background-position: 74% 16% !important;
  background-size: cover;
  height: 340px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  div{
      padding-top: 120px;
  }
}



#blocDr{
  background: #f9f9fb !important;
  height: 200px;
  width: 100%;
  background-repeat: no-repeat;

  div{
      padding-top: 50px;
  }
}

#blocHome{
  background: url('../../public/images/bg/cabinet-medical-empuriabrava-1-scaled.jpg');
  background-position: center;
  background-size: cover;
  height: 680px;
  width: 100%;
  background-repeat: no-repeat;

  .container{
    position: relative;
    height: 100%;


    .to_bottom{
      position: absolute;
      font-size: 16px;
      bottom: 50px !important;
      a{
        font-weight: 600;
        padding: 15px 20px;
        border-radius: 8px;
      }
    }

  }
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Crete Round', Georgia, "Times New Roman", serif;
}

#services{
  h2{
    color: #000;
  }
  .service{
    height: 300px;
    a{
      border-radius: 8px;
      height: 100%;
      display: block !important;
      background-attachment: scroll !important;
      background-clip: border-box !important;
      background-color: rgb(221, 220, 218) !important;
      background-origin: padding-box!important;
      background-position-x: 0% !important;
      background-position-y: 50% !important;
      background-repeat: no-repeat !important;
      background-size: cover!important;

      padding-top: 109px;
      padding-right: 40px;
      padding-bottom: 109px;
      padding-left: 40px;

      h3{
        font-size: 24px;
        color: #000;

      }
    }
  }
}

#home_cabinet{
  p{
    margin-bottom: 20px;
  }
}

#contact_info{
  .info1{
    @extend .mt-4, .mb-4;

    background-size: initial;
    background-position: right 0px top;
    background: url('../../public/images/headphones.png') #ffdeb0 no-repeat;
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 40px;
    background-position: 90% 45% !important;
    border-radius: 8px !important;
    height: 100%;
  }
  .info2{
    @extend .mt-4, .mb-4;

    background-size: initial;
    background-position: right 0px top;
    background: url('../../public/images/package.png') #f5f0ea no-repeat;
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 40px;
    background-position: 90% 45% !important;
    border-radius: 8px !important;
    height: 100%;
  }

  .fa{
    padding-right: 10px;
    font-size: 32px;
    color: $bleu;
  }
}

footer{
  position: relative;
  .socials{
//    @extend .list-group, .list-group-horizontal, .list-unstyled;
  //  direction:rtl;
    padding-right: 0;

    display: flex;
    margin-left: auto;
    text-align: right;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-end !important;
    }


    .li{
      padding: 10px;
      align-items: center;
      text-align: center;
    }

    i{
      color: rgb(255, 147, 0) !important;
      font-size: 25px !important;
    }
  }

  .links{
    padding-top: 25px;
    padding-bottom: 25px;

    border-top: 1px dotted rgba(255,255,255,0.16);
    border-bottom: 1px dotted rgba(255,255,255,0.16);

    .title{
      color: #fff;
      font-family: "Crete Round", Georgia, "Times New Roman", serif;
      font-size: 20px;
    }

    color: rgba(255, 255, 255, 0.8);
    a{
      color: rgba(255, 255, 255, 0.8) !important;
    }
    .fa{
      color: $bleu;
    }
    li{
      padding-bottom: 10px;
    }
  }
}

.text-grey, .text-grey p{
  color: rgba(0, 0, 0, 0.8);
}

.text-lightgrey{
  color: rgba(255, 255, 255, 0.8) !important
}

form[name=contact]{
  input, select, textarea{
    background-color: #eee!important;
    color: rgb(153, 153, 153);
    padding: 16px !important;
    border-radius: 0;
    height: auto;
  }
}

#servicesPage{
  padding-top: 15px;
  padding-bottom: 15px;
  background: url('../../public/images/bg/cabinet-medical-empuriabrava-1-scaled.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;


  .service{
    padding-top: 45px;
    padding-bottom: 45px;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    height: 100%;
  }
}

.btn{
  border-radius: 8px !important;
  font-weight: 600 !important;
}


.btn-lg{
  box-shadow: 0 26px 32px 0 rgba(0, 10, 102, 0.51) !important;
  border-radius: 8px !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 25px !important;
  min-width: 129px;
}

.temoignage{
  color: rgba(0,0,0,0.54)!important;
}

.procedure h3{
  padding: 16px 50px 16px 16px !important;
  color: #000000;
  font-size: 20px;
  background-color: rgb(249, 249, 251);
}

.text-grey{
  color: #666;
}

.categories-sidebar{
  border-radius: 8px;
  h4{
    background-color: rgb(245, 240, 234);
    padding: 10px;
    font-size: 20px;
    margin-bottom: 40px;
    box-shadow: 0 26px 20px 0 rgba(42, 42, 42, 0.1);
  }
  ul{
    margin-block-start: 0;
    padding-inline-start: 0px;
    @extend .list-unstyled;
  }
  li{
    padding: 10px;
  }

  li:not(.no-border):not(:last-of-type){
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: $bleu;
  }
}

.articles-sidebar{
  border-radius: 8px;

  h4{
    background-color: rgb(245, 240, 234);
    padding: 10px;
    font-size: 20px;
    margin-bottom: 40px;
    box-shadow: 0 26px 20px 0 rgba(42, 42, 42, 0.1);
  }
  ul{
    margin-block-start: 0;
    padding-inline-start: 20px;
    @extend .list-unstyled;
  }
  li{
    padding: 10px;
  }

  li:not(:last-of-type){
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: $bleu;
  }
}

.gradient-text-blue {
    background: linear-gradient(180deg, #0077FF 0%, #772ADB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.flip-box {
  background-color: transparent;
  height: 300px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.12);
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);

  .btn-white{
    color: rgb(92, 116, 241);
    border-radius: 25px 25px 25px 25px;
    overflow: hidden;
    background-color: #ffffff;
    padding-top: 15px !important;
    padding-right: 30px !important;
    padding-bottom: 15px !important;
    padding-left: 30px !important;
    font-family: 700;
  }
}

/* Style the front side */
.flip-box-front {
  background-color: #fff;
  color: black;
//  align-content: center;

}
.flip-box-front img{
  object-fit: cover;
  height: 200px;
}
/* Style the back side */
.flip-box-back {
  background-color: rgb(92, 116, 241);
  color: white;
  transform: rotateY(180deg);
//  align-content: center;

}/*
.flip-box:hover .flip-box-inner {
  transform: rotateX(180deg);
}

.flip-box-back {
  transform: rotateX(180deg);
}
*/

.to_bottom{
  h1{
    font-size: 40px;
  }
}

.dropdown-toggle.service-drop{
  height: 55px !important;
  white-space: normal;
}

.dropdown-toggle.service-drop:after{
  color: orange !important;
}

.dropdown-service{
  background-color: rgb(65 60 60 / 71%);
  width: 100%;
  a{
    color: orange !important;
    text-decoration: underline;
  }
}

#servicesPage .service{
  min-height: 240px;
  a{
    padding-left: 15px;
    padding-right: 15px;
  }
}
